import { Helmet } from "react-helmet";
import Section from "../../components/layout/Section";
import Container from "../../components/layout/Container";
import SVGHeading from "../../components/layout/SVGHeading";
import Group from "../../components/layout/Group";
import Quote from "../../components/layout/Quote";
import Crosslinks from "../../components/layout/Crosslinks";
import Image from "../../components/layout/Image";
import Lage01 from "../../components/de/Lage01";
import Lage02 from "../../components/de/Lage02";
import AnimatedPattern from "../../components/layout/AnimatedPattern";

import lageHeader from "../../assets/images/lageHeader.jpg";
// width="1920" height="1001"
import lageHeaderMobile from "../../assets/images/lageHeaderMobile.jpg";
// width="1000" height="1778"
import flexibel from "../../assets/images/flexibel.jpg";
// width="1920" height="1015"
import fastBike from "../../assets/images/fastbike.jpg";
// width="903" height="1130"
import image06 from "../../assets/images/image06.jpg";
// width="1050" height="1308"
import image07 from "../../assets/images/image07.jpg";
// width="1920" height="1250"
import image08 from "../../assets/images/image08.jpg";
// width="2100" height="1364"
import image09 from "../../assets/images/image09.jpg";
// width="1185" height="830"
import image12 from "../../assets/images/image12.jpg";
// width="903" height="666"
import video01 from "../../assets/videos/change-lage-01-s.mp4";
// width="1400" height="850"
import video02 from "../../assets/videos/change-lage-02-s.mp4";
// width="764" height="1042"

import useWindowWidth from "../../hooks/useWindowWidth";

const Lage = () => {
  const windowWidth = useWindowWidth();
  return (
    <main className="site-main lage-page">
      <Helmet>
        <title>Lage - The Change</title>
        <meta name="title" content="Lage - The Change" />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://thechange-eschborn.de/de/lage"
        />
        <meta property="og:title" content="Lage - The Change" />

        <meta
          property="twitter:url"
          content="https://thechange-eschborn.de/de/lage"
        />
        <meta property="twitter:title" content="Lage - The Change" />
      </Helmet>
      <Section className="hero">
        {windowWidth < 480 ? (
          <Image
            src={lageHeaderMobile}
            className="hero__image"
            alt="Drone shot of frankfurt"
            hash={"LbG]2QIpxuWC?wIVt6WB%gRks:WB"}
            width="1000"
            height="1778"
          />
        ) : (
          <Image
            src={lageHeader}
            className="hero__image"
            alt="Drone shot of frankfurt"
            hash={"LbG]2QIpxuWC?wIVt6WB%gRks:WB"}
            width="1920"
            height="1001"
          />
        )}
        <Container>
          <SVGHeading
            alt="Eschborn Born for Change"
            style={{ maxWidth: "918px" }}
          >
            <Lage01 />
          </SVGHeading>
        </Container>
      </Section>
      <Section>
        <Container className="media-text no-padding-right">
          <Group style={{ flexBasis: "530px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Eine renommierte Nachbarschaft ist gespannt auf Ihren guten Namen.
            </h2>
            <p>
              Hier in Eschborn sind Sie von den von den besten Namen umgeben.
              Und natürlich von den besten Möglichkeiten in The Change. Als
              Standort, der schon seit mehr als 50 Jahren stark als
              Unternehmens-Hotspot geprägt ist, haben sich hier sowohl Global
              Player, echte Blue Chips und innovative Unternehmen
              niedergelassen. Das hat gute Gründe: Zum einen ist die
              Infrastruktur auf die wirtschaftliche Entwicklung ausgelegt, zum
              anderen überzeugt der Geist von Internationalität und Dynamik, der
              als Netzwerk für gegenseitigen Austausch und Inspiration genutzt
              werden kann. Dieser Netzwerk-Charakter hat hier zugleich einen
              starken digitalen Antrieb. Der größte Internetknoten der Welt,
              ­DE-CIX, befindet sich in Frankfurt und sorgt mit einem
              durchschnittlichen Datendurchsatz von bis zu 10 Terrabit pro
              Sekunde für eine schnelle und stabile Internetverbindung. Davon
              profitieren Sie in Ihrem neuen Office durch einen
              Hochgeschwindigkeits-Glasfaseranschluss. Sie sehen, Sie sind eine
              gute Adresse. Eine, die Zukunft adressiert.
            </p>
          </Group>
          <Group style={{ flexBasis: "200px", flexGrow: 9999 }}>
            <img
              src={image06}
              alt="Seit 2016 330% Hebesatz"
              width="1050"
              height="1308"
            />
          </Group>
        </Container>
      </Section>

      <Section>
        <img
          src={image07}
          alt=""
          width="1920"
          height="1250"
          style={{ height: "auto" }}
        />
      </Section>

      <Section className="legende">
        <Container className="legende__container">
          <div className="item">
            <p className="key is-font-size-s">
              SAP
              <br />
              <span>Frankfurter Strasse 1-9</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Samsung
              <br />
              <span>Frankfurter Strasse 2-8</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              EY
              <br />
              <span>Mergenthalerallee 3-5</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Vodafone / Huawei
              <br />
              <span>Düsseldorfer Strasse 15</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Orange
              <br />
              <span>Rahmannstrasse 11</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Hyundai Mobis / ManPower
              <br />
              <span>Frankfurter Str. 60-68</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              British Telekom
              <br />
              <span>Frankfurter Strasse 21-25</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Bundesamt für Wirtschaft und Ausfuhrkontrolle
              <br />
              <span>Mergenthalerallee 26-36</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              PSD Bank
              <br />
              <span>Mergenthalerallee 31-33</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Deutsche Boerse
              <br />
              <span>Mergenthalerallee 61</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Siemens Healthineers
              <br />
              <span>Frankfurter Straße 102-110</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Randstad
              <br />
              <span>Frankfurter Strasse 100</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              DXC Technology
              <br />
              <span>Alfred-Herrhausen-Allee 3-5</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              Deutsche Bahn
              <br />
              <span>Alfred-Herrhausen-Allee 3-5</span>
            </p>
          </div>
          <div className="item">
            <p className="key is-font-size-s">
              LG
              <br />
              <span>Alfred-Herrhausen-Allee 3-5</span>
            </p>
          </div>
        </Container>
      </Section>

      <Section>
        <video
          src={video01}
          alt=""
          playsInline
          muted
          loop
          autoPlay
          width="1400"
          height="850"
          style={{ objectFit: "cover", height: "auto" }}
        />
      </Section>

      <Section>
        <img
          src={image08}
          alt="karte frankfurt umgebung"
          width="2100"
          height="1364"
          style={{ height: "auto" }}
        />
        <Container>
          <Group className="three-columns">
            <div className="column">
              <div className="is-font-family-tabac is-font-size-s">S-Bahn</div>
              <p>
                S-Bahn: 14 Min. zu Fuß, 4 min mit Rad oder Roller – S3
                Darmstadt/Bad Soden; S4 Kronberg/Langen; mit S-Bahn 12 Min. zum
                HBF
              </p>
            </div>
            <div className="column">
              <div className="is-font-family-tabac is-font-size-s">
                Autobahn
              </div>
              <p>
                1 km; 3 Min. zur A66 Richtung Wiesbaden/Frankfurt Nord; über
                Autobahnkreuz Anschluss zur A5 Richtung Norden u. Süden
              </p>
            </div>
            <div className="column">
              <div className="is-font-family-tabac is-font-size-s">
                Flughafen
              </div>
              <p>15 Min. mit dem Auto</p>
            </div>
          </Group>
        </Container>
      </Section>

      <Section>
        <Container className="media-text">
          <Group style={{ flexBasis: "530px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Perfekte Anbindung. In Zukunft auch im Westen Neues.
            </h2>
            <p>
              In 15 Minuten mit dem Auto zum Flughafen und in drei auf die
              Autobahn. Oder in 12 Minuten mit der S-Bahn zum Hauptbahnhof und
              dann überall hin. In einem Satz: Die Anbindung von The Change ist
              multioptional ideal. Das liegt zum Einen an der grundsätzlichen
              Bedeutung der Metropolregion FrankfurtRheinMain – und im
              Besonderen am Standort Eschborn mit einer gewachsenen
              Infrastruktur für Pendler. Mit dem Ausbau der geplanten
              Regionaltangente West werden zukünftig sogar Dreieich und
              Neu-Isenburg mit dem Flughafen, Eschborn und dem Hochtaunuskreis
              auf direktem Weg verbunden.
            </p>

            <p>
              Der Wandel in der Mobilität geht also weiter. Deshalb ist The
              Change auch für E-Mobilität und entsprechenden Stellplätzen und
              Ladestationen gut vorbereitet und ausbaubar.
            </p>
          </Group>
          <Group style={{ flexBasis: "200px", flexGrow: 9999 }}>
            <video
              src={video02}
              playsInline
              autoPlay
              loop
              muted
              width="764"
              height="1042"
              style={{ height: "auto" }}
            />
          </Group>
        </Container>
      </Section>

      <Quote
        quoteBg={flexibel}
        alt="nachhaltig, flexibel, zukunftssicher, individuell"
        width="1920"
        height="1015"
      >
        <SVGHeading
          alt="nachhaltig, flexibel, zukunftssicher, individuell"
          style={{ maxWidth: "880px" }}
        >
          <Lage02 />
        </SVGHeading>
      </Quote>

      <Section>
        <Container className="collage collage--fancy">
          <Group>
            <img
              src={fastBike}
              alt=""
              loading="lazy"
              width="903"
              height="1130"
              style={{ height: "auto" }}
            />
          </Group>
          <Group>
            <h2 className="is-font-size-m is-font-family-regular">
              Energiegeladen. Gilt für Bikes, Autos und Menschen.
            </h2>
            <p>
              Gute Energie, die entsteht für Menschen im Wesentlichen durch zwei
              Faktoren: Bewegung und positive Erlebnisse. Wir sind überzeugt
              davon, dass auch ein Gebäude ein Unterstützer dieser Bedingungen
              sein kann. Ganz konkret, in dem sich The Change mit der
              Mitarbeiterperspektive auseinandergesetzt hat.
            </p>

            <p>
              So hat unser konzeptionelles Denken nicht bei einer modernen
              Hochgarage mit Photovoltaikdach und ausreichend Ladestationen für
              E-Autos und E-Bikes aufgehört. Wer mit dem Fahrrad ankommt oder
              mit dem Lastenrad eine Besorgung in der vielseitigen
              Einkaufsumgebung erledigt hat, möchte sich auch frisch machen oder
              sich umziehen. Dafür stehen zentrale Duschen und Umkleidekabinen
              zur Verfügung – natürlich auch für diejenigen, die im
              nahegelegenen Arboretum eine Joggingrunde drehen wollen, bevor es
              wieder in ein Meeting geht. Und auf der Fahrt nach Hause genießt
              man auch das gute Gefühl, dass Auto oder Bike mit regenerativer
              Energie geladen worden sind.
            </p>
          </Group>
          <Group>
            <img
              src={image09}
              alt=""
              loading="lazy"
              width="1185"
              height="830"
            />
            <AnimatedPattern />
          </Group>
        </Container>
      </Section>

      <Crosslinks
        title="Arbeiten im Morgen"
        href="/de/objekt"
        linkTitle="Objekt"
        image={image12}
        width="903"
        height="666"
      />
    </main>
  );
};

export default Lage;
