import Button from "./Button";
import Group from "./Group";

import Section from "./Section";
import SVGHeading from "./SVGHeading";
import Container from "./Container";

import contactImage from "../../assets/images/beratung-und-anfrage.png";
import useWindowWidth from "../../hooks/useWindowWidth";

function ContactBlock({
  hasAddress = false,
  hasBackground = true,
  hasHeadline = true,
}) {
  const windowWidth = useWindowWidth();

  return (
    <Section
      className="contact"
      style={{ backgroundColor: hasBackground ? "var(--gray)" : "" }}
    >
      <Container className="contact__container">
        <Group>
          {hasHeadline && (
            <SVGHeading alt="Get in touch" style={{ maxWidth: "460px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 441.78 263.38"
              >
                <g>
                  <g>
                    <g className="animated-svg">
                      <path d="M0 66.64C0 28.56 29.92 0 66.13 0c21.59 0 37.57 8.67 52.36 26.18l-10.2 9.52C97.75 21.42 83.64 13.26 66.47 13.26c-29.07 0-52.19 23.12-52.19 53.38s23.8 53.04 50.32 53.04 43.18-17.85 44.54-40.46v-.17H72.25V65.96h52.19v3.06c0 38.25-24.14 63.92-60.18 63.92S0 103.53 0 66.64ZM215.9 93.5h-61.71c.51 15.81 10.03 27.2 25.33 27.2 10.88 0 17.85-4.93 23.29-13.94l11.22 6.8c-7.14 12.07-18.36 19.38-35.02 19.38-23.46 0-38.93-17.17-38.93-41.99s15.64-41.82 38.59-41.82 37.23 17.51 37.23 40.63v3.74Zm-13.94-11.22c-1.19-11.73-9.86-20.91-23.12-20.91s-21.59 8.84-23.8 20.91h46.92ZM237.66 130.56V64.09H224.4V51.34h13.26V22.1h13.6v29.24h14.45v12.75h-14.45v66.47h-13.6Z" />
                    </g>
                  </g>
                  <g>
                    <g className="animated-svg">
                      <path d="M154.61 261.56v-87.88h-23.79v-10.14h58.11v10.14h-23.66v87.88h-10.66ZM187.11 231.01c0-17.42 13.13-31.72 31.98-31.72s32.11 13.91 32.11 31.98-13.26 32.11-32.24 32.11-31.85-13.91-31.85-32.37Zm53.56.26c0-13-8.97-22.62-21.58-22.62s-21.45 9.62-21.45 22.49c0 13.65 8.84 22.88 21.45 22.88s21.58-9.23 21.58-22.75ZM264.46 236.47v-35.49h10.4v35.1c0 11.7 4.03 17.81 14.04 17.81s14.17-6.5 14.17-17.81v-35.1h10.4v35.49c0 16.38-7.93 26.78-24.57 26.78-15.6 0-24.44-8.97-24.44-26.78ZM326.6 231.4c0-18.2 13-32.11 32.37-32.11 6.89 0 12.87 1.95 16.77 4.55v11.83c-4.81-4.68-9.88-7.02-17.03-7.02-13.13 0-21.58 9.75-21.58 22.62s7.93 22.75 21.32 22.75c7.02 0 12.22-2.34 17.29-6.76v11.31c-4.16 2.86-10.14 4.81-16.77 4.81-19.37 0-32.37-13.65-32.37-31.98ZM391.34 261.56v-106.6h10.4v54.6h.26c4.03-6.24 10.27-10.27 19.37-10.27 13.52 0 20.41 8.58 20.41 24.31v37.96h-10.4v-35.62c0-12.35-4.55-17.29-13.26-17.29-9.88 0-16.38 6.11-16.38 20.8v32.11h-10.4Z" />
                    </g>
                  </g>
                  <g>
                    <g className="animated-svg">
                      <path d="M67.77 171.66c0-1.15.42-2.15 1.26-2.99.84-.84 1.85-1.26 3.02-1.26s2.21.42 3.05 1.26c.84.82 1.26 1.83 1.26 3.02s-.42 2.21-1.26 3.05c-.82.84-1.83 1.26-3.02 1.26s-2.21-.42-3.05-1.26c-.84-.84-1.26-1.87-1.26-3.08Zm7.59 11.87v28.54h-6.59v-28.54h6.59ZM83.7 183.52h6.62v2.64c2.3-2.29 4.9-3.43 7.79-3.43 3.32 0 5.91 1.04 7.76 3.13 1.6 1.78 2.4 4.68 2.4 8.7v17.49h-6.62v-15.94c0-2.81-.39-4.76-1.17-5.83-.76-1.09-2.15-1.64-4.16-1.64-2.19 0-3.74.72-4.66 2.17-.9 1.43-1.35 3.92-1.35 7.47v13.77h-6.62v-28.54Z" />
                    </g>
                  </g>
                </g>
              </svg>
            </SVGHeading>
          )}
          <p className="contact__intro-paragraph">
            Wandel braucht Raum, der Wandel ermöglicht. Und zuerst ein
            gemeinsames Gespräch.
          </p>
          {hasAddress && (
            <p>
              <strong>Kontakt</strong>
              <br />
              PE Mergenthalerallee GmbH
              <br />
              Kleine Wiesenau 1<br />
              60323 Frankfurt am Main
              <br />
              <br />
              Ein Joint Venture von Bauwens und ampure.
            </p>
          )}
          <p className="contact__telefon">T. 069 9726458 12</p>
          <Button
            className="contact__button"
            type="link"
            to="mailto:welcome@thechange-eschborn.de"
          >
            E-Mail senden
          </Button>
        </Group>
        {windowWidth > 480 && (
          <Group>
            <img
              src={contactImage}
              alt="Support-Mitarbeiterin"
              loading="lazy"
            />
          </Group>
        )}
      </Container>
    </Section>
  );
}

export default ContactBlock;
