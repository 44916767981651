import { Helmet } from "react-helmet";
import Section from "../../components/layout/Section";
import Container from "../../components/layout/Container";

function Impressum() {
  return (
    <main className="site-main impressum-page offset">
      <Helmet>
        <title>Impressum - The Change</title>
        <meta name="title" content="Impressum - The Change" />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://thechange-eschborn.de/de/impressum"
        />
        <meta property="og:title" content="Impressum - The Change" />

        <meta
          property="twitter:url"
          content="https://thechange-eschborn.de/de/impressum"
        />
        <meta property="twitter:title" content="Impressum - The Change" />
      </Helmet>
      <Section>
        <Container>
          <h2 className="is-font-family-tabac is-font-size-m">Impressum</h2>
          <h3>Angaben gemäß § 5 TMG</h3>
          PE Mergenthalerallee GmbH
          <br />
          Kleine Wiesenau 1<br />
          60323 Frankfurt am Main
          <br />
          Vertreten durch die Geschäftsführer Volker Geenen und Philipp Uhrig
          <br />
          <h3>Kontakt</h3>
          Telefon: +49 (0)69 9726458 12
          <br />
          E-Mail:{" "}
          <a
            href="mailto:welcome@thechange-eschborn.de"
            style={{ color: "inherit" }}
          >
            welcome@thechange-eschborn.de
          </a>
          <h3>Registereintrag</h3>
          Eintragung im Handelsregister, Registergericht: Amtsgerichts Frankfurt
          am Main, Registernummer: HRB 125050
          <h3>Umsatzsteuer-ID</h3>
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
          DE352243177
          <h3>Gender</h3>
          Die in dieser Website gewählte männliche Form bezieht sich immer
          zugleich auf weibliche, männliche und diverse Personen. Auf eine
          Mehrfachbezeichnung wird in der Regel zugunsten einer besseren
          Lesbarkeit verzichtet. Darüber hinaus setzen wir uns für kulturelle
          Offenheit und gegen jegliche Form von Diskriminierung ein.
          <h3>EU-Streitschlichtung</h3>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:
          https://ec.europa.eu/consumers/odr/.
          <br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
          <h3>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h3>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
          <h3>Inhaltlich verantwortlich gemäß § 55 RStV</h3>
          PE Mergenthalerallee GmbH
          <br />
          Volker Geenen und Philipp Uhrig
          <br />
          Kleine Wiesenau 1<br />
          60323 Frankfurt am Main
          <h3>Haftung für Inhalte</h3>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
          hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
          <h3>Haftung für Links</h3>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
          <br />
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
          <h3>Urheberrecht</h3>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          <br />
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
          <h3>Design und Konzeption</h3>
          <a
            href="https://quandelstaudt.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: "inherit" }}
          >
            Quandel Staudt Design GmbH
          </a>
          <br />
          Schleusenstraße 17
          <br />
          60327 Frankfurt
        </Container>
      </Section>
    </main>
  );
}

export default Impressum;
