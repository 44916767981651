import { Helmet } from "react-helmet";

import Container from "../../components/layout/Container";
import Section from "../../components/layout/Section";
import Group from "../../components/layout/Group";
import SVGHeading from "../../components/layout/SVGHeading";
import FloorplanViewer from "../../components/layout/FloorplanViewer";
import Crosslinks from "../../components/layout/Crosslinks";
import Image from "../../components/layout/Image";
import Arbeitswelten01 from "../../components/de/Arbeitswelten01";

import arbeitsweltenHeader from "../../assets/images/arbeitsweltenHeader.jpg";
// width="1920" height="1371"
import futureWorkPlace from "../../assets/images/futureWorkPlace.jpg";
// width="1031" height="843"
import image02 from "../../assets/images/image02.jpg";
// width="903" height="739"
import image03 from "../../assets/images/image03.jpg";
// width="1920" height="1269"
import image04 from "../../assets/images/image04.jpg";
// width="905" height="1035"
import image13 from "../../assets/images/image13.jpg";
// width="903" height="666"
import collagePattern from "../../assets/patterns/small_green_static.svg";
import floorplan01 from "../../assets/floorplans/change-grundriss-sockelbau.jpg";
import floorplan02 from "../../assets/floorplans/change-grundriss-zaessurgeschoss.jpg";
import floorplan03 from "../../assets/floorplans/change-grundriss-hochhaus-new-work.jpg";
import floorplan04 from "../../assets/floorplans/change-grundriss-hochhaus-einzel.jpg";
import stack01 from "../../assets/floorplans/change-gebaeude-schnitt-sockelbau.jpg";
import stack02 from "../../assets/floorplans/change-gebaeude-schnitt-zaesurgeschoss.jpg";
import stack03 from "../../assets/floorplans/change-gebaeude-schnitt-new-work.jpg";
import stack04 from "../../assets/floorplans/change-gebaeude-schnitt-einzelbuerus.jpg";
import nachhaltigkeitIntroductionVideo from "../../assets/videos/change-arbeitswelten-01-s.mp4";
// width="1400" height="850"
import attractive from "../../assets/videos/change-arbeitswelten-04-s.mp4";
// width="764" height="1042"

const floorplans = [
  {
    name: (
      <>
        Sockelbau
        <br />
        Open Space
      </>
    ),
    floorplan: floorplan01,
    stack: stack01,
  },
  {
    name: (
      <>
        Zäsurgeschoss
        <br />
        Terrassen
      </>
    ),
    floorplan: floorplan02,
    stack: stack02,
  },
  {
    name: (
      <>
        Hochhaus
        <br />
        New Work
      </>
    ),
    floorplan: floorplan03,
    stack: stack03,
  },
  {
    name: (
      <>
        Hochhaus
        <br />
        Einzelbüros
      </>
    ),
    floorplan: floorplan04,
    stack: stack04,
  },
];

function Arbeitswelten() {
  return (
    <main className="site-main arbeitswelten-page">
      <Helmet>
        <title>Arbeitswelten - The Change</title>
        <meta name="title" content="Arbeitswelten - The Change" />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://thechange-eschborn.de/de/arbeitswelten"
        />
        <meta property="og:title" content="Arbeitswelten - The Change" />

        <meta
          property="twitter:url"
          content="https://thechange-eschborn.de/de/arbeitswelten"
        />
        <meta property="twitter:title" content="Arbeitswelten - The Change" />
      </Helmet>
      <Section className="hero">
        <Image
          src={arbeitsweltenHeader}
          className="hero__image"
          alt="Arbeitswelten Header"
          hash={"LfHB-vt7kCof~pofWVj[Rkf7Rjj["}
          width="1920"
          height="1371"
        />
        <Container>
          <SVGHeading
            alt="Wandel braucht Raum, der Wandel ermöglicht."
            style={{ maxWidth: "915px" }}
          >
            <Arbeitswelten01 />
          </SVGHeading>
        </Container>
      </Section>

      <Section>
        <Container className="media-text">
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Die Möglichkeit offener Raumstrukturen. Willkommen im Team.
            </h2>
            <p>
              Eine der wichtigsten Bewegungen im Bereich New Work ist der Aufbau
              agiler Arbeitsweisen und interdisziplinärer Teams. Der Austausch
              von Wissen und Ideen löst starre Hierarchien und geschlossene
              Arbeitsplätze ab. Wir bieten für solche Arbeitsweisen alle
              Planungsmöglichkeiten für offene Flächen, die ganz individuell mit
              Einzelbüros oder informellen Zonen ergänzt werden können. Ganz
              offen eben.
            </p>
          </Group>
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <img
              src={image02}
              alt="Natur"
              loading="lazy"
              width="903"
              height="739"
            />
          </Group>
        </Container>
      </Section>

      <Section>
        <Container className="collage collage--default">
          <Group>
            <video
              src={attractive}
              alt=""
              playsInline
              loop
              muted
              autoPlay
              width="764"
              height="1042"
              style={{ height: "auto" }}
            />
          </Group>
          <Group>
            <img src={collagePattern} alt="" loading="lazy" />
          </Group>
          <Group>
            <img
              src={futureWorkPlace}
              alt=""
              loading="lazy"
              width="1031"
              height="843"
            />
          </Group>
        </Container>
      </Section>

      <Section>
        <Container className="media-text no-padding-right ">
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Geschlossene Raumstrukturen. Willkommen im Fokus.
            </h2>
            <p>
              Hochfokussierte Teams, konzentrierte Einzelarbeit oder
              Compliance-Vorschriften: Wenn geschlossene Raumstrukturen für Ihr
              Unternehmen richtig sind, dann erfüllt The Change
              selbstverständlich Ihre Pläne. Ganz flexibel auch im Zusammenspiel
              mit offeneren Lösungen – und immer mit der einzigartigen
              Atmosphäre mit natürlichen Holzstrukturen und dem hohen Standard
              eines durch und durch nachhaltigen Gebäudekonzeptes.
            </p>
          </Group>
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}></Group>
        </Container>
      </Section>

      <Section>
        <video
          src={nachhaltigkeitIntroductionVideo}
          autoPlay
          playsInline
          loop
          muted
          width="1400"
          height="850"
          style={{ objectFit: "cover", height: "auto" }}
        />
      </Section>

      <Section>
        <Container className="media-text">
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <img
              src={image04}
              alt=""
              loading="lazy"
              width="905"
              height="1035"
            />
          </Group>
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Eine Visitenkarte für Nachhaltigkeit und Stil: Ein überzeugender
              Empfang.
            </h2>
            <p>
              Wenn sich die Lobby öffnet, öffnet sich auch ein überzeugender
              Eindruck: Hier in der gut proportionierten Empfangshalle werden
              Mitarbeiter und Gäste mit gutem Stil und bester Orientierung
              empfangen. Dafür sorgt ein schöner Steinboden, eine repräsentative
              Raumhöhe und alles, was The Change besonders macht: Die
              Holz-Hybridbauweise, die dichte grüne Bepflanzung, die Gewissheit
              in einem Gebäude zu sein, in dem der Wandel schon angekommen ist.
              Das können Besucher auf bequemen Sitzmöglichkeiten genießen, dabei
              aus dem Restaurant einen Kaffee holen und das WLAN nutzen. Bis sie
              sicher und freundlich zum eigentlichen Treffpunkt geleitet werden.
            </p>
          </Group>
        </Container>
      </Section>

      <Section>
        <img
          src={image03}
          alt=""
          loading="lazy"
          width="1920"
          height="1269"
          style={{ height: "auto" }}
        />
      </Section>

      <Section>
        <Container className="media-text">
          <Group style={{ flexBasis: "600px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Wandel braucht Begegnung, die inspiriert.
            </h2>
            <p>
              New Work ist einer der wichtigsten Treiber des Wandels für
              Unternehmen. Dabei spielt das Prinzip agiler Begegnungen von
              Mitarbeitern eine zentrale Rolle. Informeller Austausch soll
              teamübergreifend gefördert werden. The Change gibt dieser
              Entwicklung ganz gezielt Raum. Mit Begegnungs- und
              Aufenthaltsbereichen, die nicht nur als Ort, sondern auch mit
              ihrer Atmosphäre inspirieren.
            </p>
          </Group>
          <Group style={{ flexBasis: "200px", flexGrow: 1 }}></Group>
        </Container>
      </Section>

      <FloorplanViewer floorplans={floorplans} />

      <Crosslinks
        title="Langfristig überzeugend"
        href="/de/nachhaltigkeit"
        linkTitle="Nachhaltigkeit"
        image={image13}
        width="903"
        height="666"
      />
    </main>
  );
}

export default Arbeitswelten;
