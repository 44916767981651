import Container from "./Container";
import Section from "./Section";

function Quote({
  children,
  quoteBg,
  alt = "",
  width = "auto",
  height = "auto",
}) {
  return (
    <Section className="quote">
      <img
        className="quote__background"
        src={quoteBg}
        alt={alt}
        loading="lazy"
        width={width}
        height={height}
      />
      <Container>{children}</Container>
    </Section>
  );
}

export default Quote;
