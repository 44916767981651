import { Helmet } from "react-helmet";
import Section from "../../components/layout/Section";
import Image from "../../components/layout/Image";
import Container from "../../components/layout/Container";
import SVGHeading from "../../components/layout/SVGHeading";
import ContactBlock from "../../components/layout/ContactBlock";
import Kontakt01 from "../../components/de/Kontakt01";
import contactHeader from "../../assets/images/contactHeader.jpg";
// width="903" height="666"

function Contact() {
  return (
    <main className="site-main contact-page">
      <Helmet>
        <title>Kontakt - The Change</title>
        <meta name="title" content="Kontakt - The Change" />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://thechange-eschborn.de/de/kontakt"
        />
        <meta property="og:title" content="Kontakt - The Change" />

        <meta
          property="twitter:url"
          content="https://thechange-eschborn.de/de/kontakt"
        />
        <meta property="twitter:title" content="Kontakt - The Change" />
      </Helmet>
      <Section className="hero">
        <Image
          src={contactHeader}
          className="hero__image"
          alt="Rendering of The Change"
          hash={"LtHM1HjDNGWA?wt7j[jYNJxus:of"}
          width="1920"
          height="1371"
        />
        <Container>
          <SVGHeading alt="Get in touch" style={{ maxWidth: "450px" }}>
            <Kontakt01 />
          </SVGHeading>
        </Container>
      </Section>
      <ContactBlock hasAddress hasBackground={false} hasHeadline={false} />
    </main>
  );
}

export default Contact;
