import { Helmet } from "react-helmet";
import Section from "../../components/layout/Section";
import Image from "../../components/layout/Image";
import Crosslinks from "../../components/layout/Crosslinks";
import Container from "../../components/layout/Container";
import SVGHeading from "../../components/layout/SVGHeading";
import Quote from "../../components/layout/Quote";
import Group from "../../components/layout/Group";
import AnimatedPattern from "../../components/layout/AnimatedPattern";
import Objekt01 from "../../components/de/Objekt01";
import Objekt02 from "../../components/de/Objekt02";

import erneuerbar from "../../assets/quotes/erneuerbar.jpg";
// width="1920" height="1015"
import mix from "../../assets/images/mix.jpg";
// width="905" height="1050"
import collagePattern from "../../assets/patterns/small_green.svg";
import theChange01 from "../../assets/images/thechange-01.jpg";
// width="1000" height="1361"
import theChange02 from "../../assets/images/thechange-02.jpg";
// width="905" height="740"
import aerial from "../../assets/images/aerial.jpg";
// width="905" height="1236"
import objectHeader from "../../assets/images/objectHeader.jpg";
// width="1920" height="1371"
import objectHeaderMobile from "../../assets/images/objectHeaderMobile.jpg";
// width="1024" height="1495"
import nachhaltigkeitIntroductionVideo from "../../assets/videos/change-objekt-03-s.mp4";
// width="1400" height="850"
import theChangeSketch from "../../assets/images/aquarell-02.jpg";
// width="1265" height="1812"
import bar from "../../assets/images/bar.jpg";
// width="1920" height="1269"
import image10 from "../../assets/images/image10.jpg";
// width="903" height="666"
import useWindowWidth from "../../hooks/useWindowWidth";

const Objekt = () => {
  const windowWidth = useWindowWidth();

  return (
    <main className="site-main nachhaltigkeit-page">
      <Helmet>
        <title>Objekt - The Change</title>
        <meta name="title" content="Objekt - The Change" />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://thechange-eschborn.de/de/objekt"
        />
        <meta property="og:title" content="Objekt - The Change" />

        <meta
          property="twitter:url"
          content="https://thechange-eschborn.de/de/objekt"
        />
        <meta property="twitter:title" content="Objekt - The Change" />
      </Helmet>
      <Section className="hero">
        {windowWidth < 480 ? (
          <Image
            src={objectHeaderMobile}
            className="hero__image"
            alt="The Change"
            hash={"L-IF6;t7M{M{_4ofRkWBWCj[ofof"}
            width="1024"
            height="1495"
          />
        ) : (
          <Image
            src={objectHeader}
            className="hero__image"
            alt="The Change"
            hash={"L-IF6;t7M{M{_4ofRkWBWCj[ofof"}
            width="1920"
            height="1371"
          />
        )}

        <Container>
          <SVGHeading alt="Ready for Tomorrow" style={{ maxWidth: "870px" }}>
            <Objekt01 />
          </SVGHeading>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2 className="is-font-size-28 is-font-family-regular">
            Wie man heute Mitarbeiter überzeugt: Mit mehr Morgen.
          </h2>
          <p className="is-font-size-28" style={{ maxWidth: "900px" }}>
            Unternehmenskultur, Employer Branding oder kämpferisch als War of
            Talents formuliert: Das Überzeugen und die Gewinnung von
            Mitarbeitern gelten als entscheidende Herausforderungen für
            Unternehmen. Aus einer logischen Überzeugung – authentischer Wandel
            geht von den Menschen aus, die ihn gestalten sollen. Und daraus
            resultiert der weitere logische Schluss, dass Mitarbeiter die besten
            Voraussetzungen bekommen sollen. Am direkten Arbeitsplatz und weit
            darüber hinaus gedacht. Mit Mobilität, mit Balance aus agilem
            Arbeiten, Aufenthaltsqualität und Erholungsmöglichkeiten.
          </p>
        </Container>
      </Section>
      <Section>
        <video
          src={nachhaltigkeitIntroductionVideo}
          autoPlay
          playsInline
          loop
          muted
          width="1400"
          height="850"
          style={{ objectFit: "cover", height: "auto" }}
        />
      </Section>
      <Section>
        <Container className="media-text no-padding-right">
          <Group style={{ flexBasis: "550px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Unten Geothermie, oben Dachterrasse. Und dazwischen keine Mauern
              im Kopf.
            </h2>
            <p style={{ maxWidth: "450px" }}>
              The Change ist Eschborns erster Office-Tower, der nicht nur als
              architektonische Persönlichkeit, sondern auch mit inneren Werten
              überzeugt. So, dass Wert entsteht. Denn das Gebäude begegnet dem
              Wandel mit einer klaren Haltung, die Sie individuell umsetzen
              können: Keine Mauern im Kopf, die Neues bremsen.
            </p>
            <p style={{ maxWidth: "450px" }}>
              Mit einer im weiten Umfeld einzigartigen Holz-Hybridbauweise, die
              spürbar CO₂ spart – aber dafür ganz und gar nicht an besonderer
              Atmosphäre.
            </p>

            <p style={{ maxWidth: "450px" }}>
              Ganz gleich, ob Sie sich für agile Arbeitsumgebungen in einem Open
              Space entscheiden, traditionellere Raumaufteilungen wünschen oder
              auf den grünen Dachterrassen mit Taunusblick spüren, dass Sie mit
              diesem Unternehmensstandort auch den richtigen Standpunkt
              eingenommen haben.
            </p>
          </Group>
          <Group style={{ flexBasis: "400px", flexGrow: 9999 }}>
            <img
              src={theChangeSketch}
              alt="Skizze The Change"
              loading="lazy"
              width="1265"
              height="1812"
            />
          </Group>
        </Container>
      </Section>
      <Quote
        quoteBg={erneuerbar}
        alt="nachhaltig, elegant, erneuerbar, inspirierend"
        width="1920"
        height="1015"
      >
        <SVGHeading
          alt="nachhaltig, elegant, erneuerbar, inspirierend"
          style={{ maxWidth: "700px" }}
        >
          <Objekt02 />
        </SVGHeading>
      </Quote>
      <Section>
        <Container className="media-text">
          <Group style={{ flexBasis: "600px", flexGrow: 1 }}>
            <h2
              className="is-font-size-m is-font-family-regular"
              style={{ maxWidth: "600px" }}
            >
              Holz und Beton: Sie würden es High-Performance Team nennen.
            </h2>
            <p style={{ maxWidth: "530px" }}>
              Mit Holz und Beton kommen in The Change zwei Materialien zum
              Einsatz, die besonders bei tieferer Betrachtung viel von Wandel
              und neuen Perspektiven erzählen können.
            </p>
            <p style={{ maxWidth: "530px" }}>
              Noch nie war die Bedeutung des Begriffes Nachhaltigkeit für
              Unternehmen größer. Als Haltung und Handlung mit hohem
              strategischen Wert. Doch neu ist dieser Begriff nicht. Schon im
              frühen 18. Jahrhundert wurde diese Bezeichnung in der
              Forstwirtschaft geprägt. Was damals vor allem in der Bedeutung als
              nachwachsender Rohstoff geprägt wurde, hat heute – auch in der
              Verbindung mit Beton – ganz neue Dimensionen. So ist Holz nicht
              nur ein echter Sparer von CO₂ in der Baustoffproduktion, sondern
              Bäume binden es auf natürlich Weise über lange Zeiträume: Ein
              Kubikmeter Holz bindet eine Tonne dieses Stoffes.
            </p>
            <p style={{ maxWidth: "530px" }}>
              Kommen wir zur Teamarbeit zwischen Holz und Beton. Man sagt, dass
              sich in einem guten Team die Stärken gegenseitig ergänzen – und
              genau so ist es auch: Beton ist in The Change nicht nur ein
              moderner und effizienter Gestalter, sondern auch ein
              hervorragender Ermöglicher für den Partner Holz. Während Beton die
              Verantwortung für Druckfestigkeit und hervorragenden Schallschutz
              übernimmt, macht sich Holz für die gesamte Tragstruktur stark und
              spielt seine Eigenschaften sowohl inhaltlich wie auch visuell aus.
              Leichtes Eigengewicht bei guter Zugfestigkeit verbinden sich
              perfekt mit der einzigartigen atmosphärischen Sichtbarkeit.
            </p>
            <p style={{ maxWidth: "530px" }}>
              Und so liefert dieses Team in allen Aspekten ab: Für eine
              effiziente Bauweise, als Ressourcen-Champion und als Designer
              eines sehr besonderen Gesamtauftrittes. Wir können uns vorstellen,
              dass sich auch Ihre Teams auf eine Zusammenarbeit freuen.
            </p>
          </Group>
          <Group style={{ flexBasis: "300px", flexGrow: 9999 }}>
            <img
              src={mix}
              alt="Beton-Holz-Hybridbauweise"
              loading="lazy"
              width="905"
              height="1050"
            />
            <AnimatedPattern />
          </Group>
        </Container>
      </Section>

      <Section>
        <img
          src={bar}
          alt=""
          loading="lazy"
          width="1920"
          height="1269"
          style={{ objectFit: "cover", height: "auto" }}
        />
      </Section>

      <Section className="data">
        <Container className="data__container">
          <div className="item-wrapper">
            <div className="item" id="item-1">
              <p className="key is-font-size-xs">
                BRUTTO-GRUNDFLÄCHE OI. (BGF-R)
              </p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-1">
                  15.800
                </span>{" "}
                m²
              </p>
            </div>
            <div className="item" id="item-4">
              <p className="key is-font-size-xs">MIETFLÄCHE OI. NACH MFG GIF</p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-4">
                  13.500
                </span>{" "}
                m²
              </p>
            </div>
            <div className="item" id="item-7">
              <p className="key is-font-size-xs">
                Teilbarkeit der Mietflächen ab
              </p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-7">
                  400
                </span>{" "}
                m²
              </p>
            </div>
          </div>

          <div className="item-wrapper">
            <div className="item" id="item-2">
              <p className="key is-font-size-xs">Effizienzgebäude-Stufe</p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-2">
                  40
                </span>
              </p>
            </div>
            <div className="item" id="item-5">
              <p className="key is-font-size-xs">HÖHE</p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-5">
                  60
                </span>{" "}
                m
              </p>
            </div>
            <div className="item" id="item-8">
              <p className="key is-font-size-xs">Dachterassen</p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-8">
                  4
                </span>
              </p>
            </div>
          </div>
          <div className="item-wrapper">
            <div className="item" id="item-3">
              <p className="key is-font-size-xs">STELLPLÄTZE</p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-3">
                  350
                </span>
              </p>
            </div>
            <div className="item" id="item-6">
              <p className="key is-font-size-xs">Komfortstellplätze (2,70 m)</p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-6">
                  65
                </span>
              </p>
            </div>
            <div className="item" id="item-9">
              <p className="key is-font-size-xs">E-Ladepunkte </p>
              <p className="value is-font-size-l">
                <span className="is-font-size-xl" id="count-9">
                  85
                </span>
              </p>
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container className="collage collage--default">
          <Group>
            <img
              src={theChange01}
              alt=""
              loading="lazy"
              width="1000"
              height="1361"
            />
          </Group>
          <Group>
            <img src={collagePattern} alt="" loading="lazy" />
          </Group>
          <Group>
            <img
              src={theChange02}
              alt=""
              loading="lazy"
              width="905"
              height="740"
            />
          </Group>
        </Container>
      </Section>

      <Section>
        <Container className="collage collage--single">
          <Group>
            <img src={aerial} alt="" loading="lazy" width="905" height="1236" />
          </Group>
          <Group>
            <img src={collagePattern} alt="" loading="lazy" />
          </Group>
        </Container>
      </Section>

      <Crosslinks
        title="Wandel braucht Raum"
        href="/de/arbeitswelten"
        linkTitle="Arbeitswelten"
        image={image10}
        width="903"
        height="666"
      />
    </main>
  );
};

export default Objekt;
